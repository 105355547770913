import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import api from "@/axios";
import Moment from "react-moment";
import "moment/locale/pl";
import MatchesCard from "@/components/cards/matches";
import ImagesCard from "@/components/cards/images";
import TvCard from "@/components/cards/tv";
import { Swiper, SwiperSlide } from "swiper/react";
import LightGallery from "lightgallery/react";
import "swiper/css/bundle";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

import ekstraklasaLogo from "@/images/leagues/ekstraklasa-black.png";
import friendlyMatchLogo from "@/images/leagues/friendly-match-black.png";
import polishCupLogo from "@/images/leagues/polish-cup.png";
import ChampionsLeagueLogo from "@/images/leagues/Logo_UEFA_Champions_League.png";
import EuropeLeagueLogo from "@/images/leagues/Europa_League_2021.png";
import ConferenceLeagueLogo from "@/images/leagues/uefa-europa-conference-league.png";

import iGoal from "@/images/icons/incidents/goal.svg";
import iSub from "@/images/icons/incidents/substitute.svg";
import iYCard from "@/images/icons/incidents/yellow-card.svg";
import iRCard from "@/images/icons/incidents/red-card.svg";
import { getMinutesPlayedLabel } from "../../helpers/index";

const incidentsImgs = {
  Goal: iGoal,
  "Goal Own": iGoal,
  "Goal Penalty": iGoal,
  Substitute: iSub,
  "Yellow Card": iYCard,
  "Red Card": iRCard,
};

const Stats = ({ playerContentData }) => {
  const {
    id,
    teamId,
    posId,
    role,
    nationality,
    birthdate,
    posName,
    shirtNumber,
    height,
    stats,
    matches,
  } = playerContentData;

  const [apiData, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/players/o/get/${id}`);
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  let statsVar = null;
  if (stats.length > 0) {
    statsVar = stats.find((v) => v.season.active === 1 && v.active === 1);
  }

  const carouselOptions = {
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    autoHeight: true,
    navigation: {
      nextEl: "#shop-next",
      prevEl: "#shop-prev",
    },
    breakpoints: {
      0: {
        spaceBetween: 5,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      576: {
        spaceBetween: 30,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 4.5,
        slidesPerGroup: 1,
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 5.5,
        slidesPerGroup: 1,
      },
    },
  };

  const carouselOptionsImages = {
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    autoHeight: true,
    navigation: {
      nextEl: "#image-next",
      prevEl: "#image-prev",
    },
    breakpoints: {
      0: {
        spaceBetween: 5,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      576: {
        spaceBetween: 30,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 4.5,
        slidesPerGroup: 1,
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 5.5,
        slidesPerGroup: 1,
      },
    },
  };

  const carouselOptionVideo = {
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    autoHeight: true,
    navigation: {
      nextEl: "#video-next",
      prevEl: "#video-prev",
    },
    breakpoints: {
      0: {
        spaceBetween: 0,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      576: {
        spaceBetween: 30,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 4.5,
        slidesPerGroup: 1,
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 5.5,
        slidesPerGroup: 1,
      },
    },
  };

  return (
    <>
      <Container className="mt-5">
        <Row className="mb-4">
          <h4 className="text-uppercase fw-bold ps-5">DANE PODSTAWOWE</h4>
        </Row>
        <Row className="mt-4 mb-5 justify-content-center player-data-row">
          <Col sm={12} md={6} lg={"auto"} className="col-12">
            <div className="px-5 text-uppercase text-center d-flex flex-column justify-content-center align-items-center player-info-box">
              <div className="player-info-box-title">Narodowość</div>
              <div className="player-info-box-subtitle">{nationality}</div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={"auto"} className="col-12">
            <div className="px-5 text-uppercase text-center d-flex flex-column justify-content-center align-items-center player-info-box">
              <div className="player-info-box-title">Data urodzenia</div>
              <div className="player-info-box-subtitle">
                <Moment date={birthdate} format="DD.MM.YYYY"></Moment>
              </div>
            </div>
          </Col>
          {posId != 4 && (
            <Col sm={12} md={6} lg={"auto"} className="col-12">
              <div className="px-5 text-uppercase text-center d-flex flex-column justify-content-center align-items-center player-info-box">
                <div className="player-info-box-title">Pozycja</div>
                <div className="player-info-box-subtitle">{posName}</div>
              </div>
            </Col>
          )}
          {posId != 4 ? (
            <Col sm={12} md={6} lg={"auto"} className="col-12">
              <div className="px-5 text-uppercase text-center d-flex flex-column justify-content-center align-items-center player-info-box">
                <div className="player-info-box-title">Numer na koszulce</div>
                <div className="player-info-box-subtitle">{shirtNumber}</div>
              </div>
            </Col>
          ) : (
            <Col sm={12} md={6} lg={"auto"} className="col-12">
              <div className="px-5 text-uppercase text-center d-flex flex-column justify-content-center align-items-center player-info-box">
                <div className="player-info-box-title">Rola w sztabie</div>
                <div className="player-info-box-subtitle">{role}</div>
              </div>
            </Col>
          )}
          <Col sm={12} md={6} lg={"auto"} className="col-12">
            <div className="px-5 text-uppercase text-center d-flex flex-column justify-content-center align-items-center player-info-box">
              <div className="player-info-box-title">Wzrost</div>
              <div className="player-info-box-subtitle">{height} cm</div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="mt-2">
        {posId != 4 && teamId == 2 && (
          <>
            <Row>
              <h4 className="text-uppercase fw-bold ps-5">
                STATYSTYKI MECZOWE - PKO BP Ekstraklasa
              </h4>
            </Row>
            {statsVar ? (
              <Row>
                <Container className="p-5 rounded text-uppercase w-75 player-stats-adv">
                  <Row>
                    <Col
                      sm={12}
                      md={6}
                      lg={4}
                      className="col-12 border-start my-sm-2 my-md-0 my-lg-0"
                    >
                      <div className="player-stats-adv-title">
                        Występy/Od 1. minuty
                      </div>
                      <div className="stats-matches orbitron-font fw-bold text-dark">
                        {`${statsVar.games_played}/${statsVar.starts}`}
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      lg={4}
                      className="col-12 border-start my-sm-2 my-md-0 my-lg-0"
                    >
                      <div className="player-stats-adv-title">
                        Minuty na boisku
                      </div>
                      <div className="stats-matches orbitron-font fw-bold text-dark">
                        {statsVar.time_played}
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      lg={4}
                      className="col-12 border-start my-sm-2 my-md-0 my-lg-0"
                    >
                      <div className="player-stats-adv-title">
                        {posId == 0 ? "Czyste konta" : "Strzelone bramki"}
                      </div>
                      <div className="stats-matches orbitron-font fw-bold text-dark">
                        {posId == 0 ? statsVar.clean_sheets : statsVar.goals}
                      </div>
                    </Col>
                  </Row>
                </Container>
                <Container className="p-5 rounded text-uppercase w-75 player-stats-adv">
                  <Row>
                    <Col
                      sm={12}
                      md={6}
                      lg={4}
                      className="col-12 border-start my-sm-2 my-md-0 my-lg-0"
                    >
                      <div className="player-stats-adv-title">
                        {posId == 0 ? "Wpuszczone bramki" : "Asysty"}
                      </div>
                      <div className="stats-matches orbitron-font fw-bold text-dark">
                        {posId == 0
                          ? statsVar.goals_conceded
                          : statsVar.goal_assists}
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      lg={4}
                      className="col-12 border-start my-sm-2 my-md-0 my-lg-0"
                    >
                      <div className="player-stats-adv-title">Żółte kartki</div>
                      <div className="stats-matches orbitron-font fw-bold text-dark">
                        {statsVar.yellow_cards}
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      lg={4}
                      className="col-12 border-start my-sm-2 my-md-0 my-lg-0"
                    >
                      <div className="player-stats-adv-title">
                        Czerwone kartki
                      </div>
                      <div className="stats-matches orbitron-font fw-bold text-dark">
                        {statsVar.total_red_cards}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Row>
            ) : (
              <div className="no-api-data-box w-75">
                BRAK STATYSTYK DLA OBECNEGO SEZONU
              </div>
            )}
            {teamId == 2 && (
              <>
                <Row className="my-4">
                  <h4 className="text-uppercase fw-bold ps-5">
                    MECZE W TYM SEZONIE
                  </h4>
                </Row>
                {matches && matches.length > 0 ? (
                  <Row>
                    {matches.map((matchData, index) => (
                      <Container
                        key={index}
                        className="text-dark player-matches-container mb-3 w-75"
                      >
                        <Row className="justify-content-center align-items-center py-3 fw-bold text-uppercase">
                          <Col xs={6} md={6} lg={"auto"} className="col-6">
                            <div className="d-flex justify-content-center">
                              {[0, 2, 3].includes(matchData.leagueId) && (
                                <img
                                  src={ekstraklasaLogo}
                                  className="me-3 player-stats-img our-matches-score-logos"
                                />
                              )}
                              {matchData.leagueId === 7 && (
                                <img
                                  src={friendlyMatchLogo}
                                  className="me-3 player-stats-img our-matches-score-logos"
                                />
                              )}
                              {matchData.leagueId === 1 && (
                                <img
                                  src={polishCupLogo}
                                  className="me-3 player-stats-img our-matches-score-logos"
                                />
                              )}
                              {matchData.leagueId === 4 && (
                                <img
                                  src={ChampionsLeagueLogo}
                                  className="me-3 player-stats-img our-matches-score-logos"
                                />
                              )}
                              {matchData.leagueId === 5 && (
                                <img
                                  src={EuropeLeagueLogo}
                                  className="me-3 player-stats-img our-matches-score-logos"
                                />
                              )}
                              {matchData.leagueId === 6 && (
                                <img
                                  src={ConferenceLeagueLogo}
                                  className="me-3 player-stats-img our-matches-score-logos"
                                />
                              )}
                            </div>
                          </Col>
                          <Col
                            xs={6}
                            md={6}
                            lg={"auto"}
                            className="col-6 text-center me-0 me-lg-5 player-stats-date"
                          >
                            <Moment
                              date={matchData.matchDate}
                              format="DD.MM.YYYY"
                            ></Moment>
                          </Col>

                          <Col className="text-end d-none d-lg-block">
                            {matchData.homeConShortName}
                          </Col>
                          <Col className="text-end player-matches-fit-content">
                            <img
                              src={
                                matchData.homeCrestImage
                                  ? `${process.env.GATSBY_API_BASE_URL}/${matchData.homeCrestImage.path}`
                                  : ""
                              }
                              className="logos-size ms-0 ms-lg-3 our-matches-score-logos"
                            />
                          </Col>
                          <Col className="text-center timetable-score player-matches-fit-content">
                            {matchData.homeGoals}
                          </Col>
                          <Col className="text-center player-stats-score-between player-matches-fit-content">
                            {":"}
                          </Col>
                          <Col className="text-center timetable-score player-matches-fit-content">
                            {matchData.awayGoals}
                          </Col>
                          <Col className="player-matches-fit-content">
                            <img
                              src={
                                matchData.awayCrestImage
                                  ? `${process.env.GATSBY_API_BASE_URL}/${matchData.awayCrestImage.path}`
                                  : ""
                              }
                              className="logos-size me-0 me-lg-3 our-matches-score-logos"
                            />
                          </Col>
                          <Col className="d-none d-lg-block">
                            {matchData.awayConShortName}
                          </Col>
                          <Col
                            sm={"auto"}
                            md={"auto"}
                            lg={"auto"}
                            className={`${
                              matchData.playerInc.length > 0
                                ? "col-6 text-start"
                                : "col-12 text-center"
                            } text-lg-end me-0 me-lg-4 player-minutes`}
                          >
                            {getMinutesPlayedLabel(
                              matchData.playerMinutesPlayed
                            )}
                          </Col>
                        </Row>
                      </Container>
                    ))}
                  </Row>
                ) : (
                  <div className="no-api-data-box w-75">
                    BRAK MECZÓW DLA OBECNEGO SEZONU
                  </div>
                )}
              </>
            )}
          </>
        )}
        {(apiData && apiData.news && apiData.news.length > 0) ||
        (apiData && apiData.movies && apiData.movies.length > 0) ||
        (apiData && apiData.images && apiData.images.length > 0) ? (
          <Row className="my-5">
            <h4 className="text-uppercase fw-bold ps-5">MATERIAŁY POWIĄZANE</h4>
          </Row>
        ) : (
          <Row className="my-5">
            <h4 className="text-uppercase fw-bold ps-5"></h4>
          </Row>
        )}

        {apiData && apiData.news && apiData.news.length > 0 && (
          <Row className="mb-5">
            <h4 className="text-uppercase fw-bold player-materials-title mt-1 mb-5 w-100 ps-2">
              WPISY
            </h4>
            {loading && <span className="loader"></span>}
            {error && (
              <div className="text-dark">{`Wystąpił problem z wczytaniem materiałów - ${error}`}</div>
            )}
            {!loading && apiData && (
              <Swiper className="tickets_slider" {...carouselOptions}>
                {apiData.news.map(
                  (post, index) =>
                    index <= 11 && (
                      <SwiperSlide key={index}>
                        <MatchesCard data={post} />
                      </SwiperSlide>
                    )
                )}
                <Container
                  fluid
                  className="slider-pag wrap swiper-margin-top pe-0"
                >
                  <Row className="justify-content-start d-flex align-items-center mt-5">
                    <Col lg={1} md={3} sm={2} className="col-3 button-prev-col">
                      <div className="button-prev" id="shop-prev"></div>
                    </Col>
                    <Col lg={1} md={3} sm={2} className="col-3 button-next-col">
                      <div className="button-next" id="shop-next"></div>
                    </Col>
                  </Row>
                </Container>
              </Swiper>
            )}
          </Row>
        )}

        {apiData && apiData.images && apiData.images.length > 0 && (
          <Row className="mb-5">
            <h4 className="text-uppercase fw-bold player-materials-title mt-1 mb-5 w-100 ps-2">
              ZDJĘCIA
            </h4>
            {loading && <span className="loader"></span>}
            {error && (
              <div className="text-dark">{`Wystąpił problem z wczytaniem materiałów - ${error}`}</div>
            )}
            {!loading && apiData && (
              <LightGallery
                speed={500}
                plugins={[lgThumbnail]}
                selector=".item"
              >
                <Swiper className="tickets_slider" {...carouselOptionsImages}>
                  {apiData.images.map(
                    (post, index) =>
                      index <= 11 && (
                        <SwiperSlide
                          className="item"
                          data-src={`${process.env.GATSBY_API_BASE_URL}/${post.path}`}
                          key={index}
                        >
                          <ImagesCard data={post} />
                        </SwiperSlide>
                      )
                  )}
                  <Container
                    fluid
                    className="slider-pag wrap swiper-margin-top pe-0"
                  >
                    <Row className="justify-content-start d-flex align-items-center mt-5">
                      <Col
                        lg={1}
                        md={3}
                        sm={2}
                        className="col-3 button-prev-col"
                      >
                        <div className="button-prev" id="image-prev"></div>
                      </Col>
                      <Col
                        lg={1}
                        md={3}
                        sm={2}
                        className="col-3 button-next-col"
                      >
                        <div className="button-next" id="image-next"></div>
                      </Col>
                    </Row>
                  </Container>
                </Swiper>
              </LightGallery>
            )}
          </Row>
        )}
        {apiData && apiData.movies && apiData.movies.length > 0 && (
          <Row className="mb-5">
            <h4 className="text-uppercase fw-bold player-materials-title mt-1 mb-5 w-100 ps-2">
              WIDEO
            </h4>
            {loading && <span className="loader"></span>}
            {error && (
              <div className="text-dark">{`Wystąpił problem z wczytaniem materiałów - ${error}`}</div>
            )}
            {!loading && apiData && (
              <Swiper className="tickets_slider" {...carouselOptionVideo}>
                {apiData.movies.map(
                  (post, index) =>
                    index <= 11 && (
                      <SwiperSlide key={index}>
                        <TvCard data={post} />
                      </SwiperSlide>
                    )
                )}
                <Container
                  fluid
                  className="slider-pag wrap swiper-margin-top pe-0"
                >
                  <Row className="justify-content-start d-flex align-items-center mt-5">
                    <Col lg={1} md={3} sm={2} className="col-3 button-prev-col">
                      <div className="button-prev" id="video-prev"></div>
                    </Col>
                    <Col lg={1} md={3} sm={2} className="col-3 button-next-col">
                      <div className="button-next" id="video-next"></div>
                    </Col>
                  </Row>
                </Container>
              </Swiper>
            )}
          </Row>
        )}
      </Container>
    </>
  );
};

export default Stats;
