import "@/css/player.css";
import React, { useState, useEffect } from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOneTop from "@/components/header-one-top";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SliderTop from "@/components/player/slider-top";
import PlayerStats from "@/components/player/stats";
import api from "@/axios";
import { navigate } from "gatsby";

const PlayerShowcase = ({ params }) => {
  const [apiData, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(
          `/players/o/get/${params.id.split("-").pop()}`
        );
        if (
          response.data &&
          response.data.status &&
          response.data.status == "error"
        ) {
          navigate("/szatnia", { replace: true });
        }
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        navigate("/szatnia", { replace: true });
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        {loading && <span className="loader"></span>}
        {!loading && (
          <Layout PageTitle={`${apiData.firstname + " " + apiData.lastname}`}>
            <HeaderOneTop />
            <HeaderOne />
            <SliderTop data={apiData} />
            <PlayerStats playerContentData={apiData} />
            <Footer />
          </Layout>
        )}
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default PlayerShowcase;
