import React from "react";
import "moment/locale/pl";

const ImagesCard = ({ data }) => {
  const { path } = data;

  return (
    <a href={`${process.env.GATSBY_API_BASE_URL}/${path}`}>
      <div className="singleTM card-container text-start">
        <div
          className="tm_img"
          style={{
            backgroundImage: `url('${process.env.GATSBY_API_BASE_URL}/${path}')`,
          }}
        >
          <img className="d-none" src={`${process.env.GATSBY_API_BASE_URL}/${path}`}  alt=""/>
          <div className="tm_overlay" />
        </div>
      </div>
    </a>
  );
};

export default ImagesCard;
