import React from "react";
import { Container, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/effect-cards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const SliderTop = ({ data }) => {
  const { firstname, lastname, facebookUrl, twitterUrl, instagramUrl } = data;
  return (
    <section
      className="slider-player"
      style={{
        backgroundImage: `url('${
          data.bannerImage
            ? `${process.env.GATSBY_API_BASE_URL}/${data.bannerImage.path}`
            : ""
        }')`,
      }}
    >
      <Container className="slider-player-container d-flex justify-content-start align-items-start w-100">
        <Row className="text-center slider-player-name-box flex-column">
          <h4>{firstname}</h4>
          <h3>{lastname}</h3>
        </Row>
        <Row className="player-social-row">
          <div className="player-socials">
            {facebookUrl && facebookUrl.length > 0 && (
              <a
                href={facebookUrl}
                className="me-3"
                target="_blank"
                rel="nofollow noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            )}

            {twitterUrl && twitterUrl.length > 0 && (
              <a
                href={twitterUrl}
                className="mx-3"
                target="_blank"
                rel="nofollow noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            )}

            {instagramUrl && instagramUrl.length > 0 && (
              <a
                href={instagramUrl}
                className="mx-3"
                target="_blank"
                rel="nofollow noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            )}
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default SliderTop;
